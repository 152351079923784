import { defineStore } from 'pinia'
import { type HealthCheck } from '~/types'

export const useHealthCheckStore = defineStore('healthcheck', () => {
  const healthcheck: Ref<HealthCheck | undefined> = ref()

  async function fetchApiHealthCheck(): Promise<void> {
    try {
      healthcheck.value = (await $hubFetch('/api/healthcheck')) as HealthCheck
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
    }
  }
  return {
    healthcheck,

    fetchApiHealthCheck
  }
})
