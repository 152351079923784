<template>
  <div
    v-if="ratingCount"
    class="flex items-center justify-center bg-trublue px-6 py-3 text-white md:ml-14 md:mt-1 md:bg-transparent md:p-0"
  >
    <span class="text-sm font-bold">{{ $n(ratingCount, 'decimal') }}</span>
    <span class="ml-2 text-xs font-semibold"> {{ $t('ratings.totalCount') }}</span>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const ratingCount = ref<number | undefined>()

watch(() => route.params.id, fetchRatingCount, { immediate: true })

async function fetchRatingCount() {
  ratingCount.value = undefined

  if (route.params.id) {
    try {
      const data = await $hubFetch(`api/v4/dashboards/${route.params.id}/reports/OrganisationAllTimeRatings`)

      ratingCount.value = data.results.rows[0][0].value as number
    } catch (error) {
      console.warn(error)
      ratingCount.value = undefined
    }
  }
}
</script>
