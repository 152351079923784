// use classes and then export as types so that we can export an array of keys
// but still only define the keys for the types once, see inspiration here:
// https://stackoverflow.com/questions/43909566/get-keys-of-a-typescript-interface-as-array-of-strings

class NormalDashboardParamsClass {
  relativeDate?: string
  dateFrom?: string
  dateTo?: string
  touchpointIds?: string | string[]
  organisationNodeIds?: string | string[]
}

class CommentDashboardParamsClass extends NormalDashboardParamsClass {
  skip?: number | string
  take?: number | string
  searchString?: string
  topMentions?: string | string[]
  isCustomQuestion?: 'true' | 'false'
  questionId?: number | string
  sentimentCategory?: string | string[]
  questionCategory?: string | string[]
}

export interface NormalDashboardParams extends NormalDashboardParamsClass { };
export interface CommentDashboardParams extends CommentDashboardParamsClass { };
export type DashboardParams = (NormalDashboardParams | CommentDashboardParams) & {
  hash?: string
  [name: string]: string | number | string[] | undefined
}

type NormalDashboardParamsArray = Array<keyof NormalDashboardParams>
type CommentDashboardParamsArray = Array<keyof CommentDashboardParams>

export const normalDashboardParamsArray: NormalDashboardParamsArray
  = Object.keys(new NormalDashboardParamsClass()) as NormalDashboardParamsArray
export const commentDashboardParamsArray: CommentDashboardParamsArray
  = Object.keys(new CommentDashboardParamsClass()) as CommentDashboardParamsArray
