<template>
  <div class="flex w-full items-center justify-center bg-light-purple py-2 text-sm text-grey-blue">
    <div
      class="ml-4 flex w-full cursor-pointer flex-wrap items-center hover:text-purple sm:justify-center sm:text-center"
      @click="goToReleasePage"
    >
      <div class="mr-2 font-semibold">
        {{ $t('releases.banner.title') }}
      </div>
      <div class="flex items-center justify-start">
        <div>
          {{ $t('releases.banner.subtitle') }}
        </div>
        <UIcon
          name="i-mdi-arrow-right-thin"
          class="ml-2 !size-5"
        />
      </div>
    </div>

    <div class="flex cursor-pointer justify-end pr-4 hover:text-purple">
      <UIcon
        name="i-mdi-close"
        @click="ignoreRelease"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const userStore = useUserStore()
const releaseStore = useReleaseStore()
const { currentUser } = storeToRefs(userStore)
const { latestRelease } = storeToRefs(releaseStore)

async function goToReleasePage() {
  navigateTo('/releases')

  if (!currentUser.value || !currentUser.value.id || !latestRelease.value) {
    useHubToast({
      type: 'danger',
      message: t('releases.error')
    })
    return
  }

  await $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      userId: currentUser.value.id,
      value: latestRelease.value.releaseNumber,
      name: 'LastAcknowledgedRelease',
      type: 'string'
    }
  })

  await userStore.fetchCurrentUserPreferences()
}

async function ignoreRelease() {
  if (!currentUser.value || !currentUser.value.id || !latestRelease.value) {
    useHubToast({
      type: 'danger',
      message: t('releases.error')
    })
    return
  }

  const setIgnoredRelease = () => $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      // @ts-expect-error this variable will always be defined
      userId: currentUser.value.id,
      // @ts-expect-error this variable will always be defined
      value: latestRelease.value.releaseNumber,
      name: 'LastIgnoredRelease',
      type: 'string'
    }
  })
  const ignoredReleaseTime = () => $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      // @ts-expect-error this variable will always be defined
      userId: currentUser.value.id,
      value: new Date().toISOString(),
      name: 'LastIgnoredReleaseTime',
      type: 'string'
    }
  })

  await Promise.all([setIgnoredRelease(), ignoredReleaseTime()])

  await userStore.fetchCurrentUserPreferences()
}
</script>
