<template>
  <div>
    <ImpersonatorBanner
      v-if="impersonatorName"
      :impersonator-name="impersonatorName"
      class="sticky top-0 z-50"
    />
    <ReleaseBanner
      v-else-if="showReleaseBanner"
      class="sticky top-0 z-50"
    />
    <NavBar
      v-model:display-mobile-menu="displayMobileMenu"
      :dashboard-dropdown-items="dashboardDropdownItems"
      :available-dashboard-count="availableDashboardCount"
      :banner-shown-above="!!(impersonatorName || showReleaseBanner)"
    />

    <USlideover
      v-model="displayMobileMenu"
      side="left"
      class="top-12"
    >
      <SidebarContent
        include-header
        :dashboard-items="dashboardDropdownItems"
        :available-dashboard-count="availableDashboardCount"
        @close="displayMobileMenu = false"
      />
    </USlideover>

    <main class="flex h-full min-h-screen flex-col justify-between bg-white md:pt-12 print:pt-0">
      <slot />

      <div class="flex items-center justify-center bg-tertiary py-4 text-xs text-grey-blue">
        © 2024 TruRating Ltd
      </div>
    </main>

    <ReleaseModal
      v-if="canReadReleases"
      :model-value="(newReleaseAvailable && latestRelease?.releaseType === 'major')"
    />
  </div>
</template>

<script setup lang="ts">
import groupBy from 'lodash/groupBy'

import type { HubCommentDashboard, HubDashboard } from '~/types/configuration'
import type { DashboardDropdownItem } from '~/types/component'

// internal refs
const displayMobileMenu: Ref<boolean> = ref(false)

const route = useRoute()
const { t } = useI18n()

// store actions
const dashboardStore = useDashboardStore()
const userStore = useUserStore()
const releaseStore = useReleaseStore()
const filterStore = useFilterStore()

const { currentUserPreferences } = storeToRefs(userStore)
const { latestRelease } = storeToRefs(releaseStore)
const { params } = storeToRefs(filterStore)

await dashboardStore.fetchDashboards()
await releaseStore.fetchReleases()
await userStore.fetchCurrentUserPreferences()
await userStore.fetchCurrentUser() // fetches user for permissions
const impersonatorName = userStore.fetchImpersonatorName()

// computed
const availableDashboardCount: ComputedRef<number> = computed(() => {
  let availableDashboardCount: number = 0

  if (dashboardDropdownItems.value.length) {
    for (const dropdownCollection of dashboardDropdownItems.value) {
      for (const item of dropdownCollection) {
        if (item.dashboard) {
          availableDashboardCount++
        }
      }
    }
  }

  return availableDashboardCount
})
const newReleaseAvailable: ComputedRef<boolean> = computed(() => {
  if (!latestRelease.value || !currentUserPreferences.value) {
    return false
  }

  const lastAcknowledgedRelease = currentUserPreferences.value.find(p => p.name === 'LastAcknowledgedRelease')
  const lastIgnoredRelease = currentUserPreferences.value.find(p => p.name === 'LastIgnoredRelease')

  if (lastAcknowledgedRelease?.value !== latestRelease.value.releaseNumber
    && lastIgnoredRelease?.value !== latestRelease.value.releaseNumber) {
    return true
  }

  return false
})
const canReadReleases = computed(() => checkPermissions({ permissions: 'Releases.Read' }))
const showReleaseBanner = computed(() =>
  canReadReleases.value
  && newReleaseAvailable.value
  && latestRelease.value
  && latestRelease.value.releaseType === 'minor')

const dashboardDropdownItems = computed(() => groupAndFormatDashboards(dashboardStore.allDashboards))

// functions
function groupAndFormatDashboards(
  dashboards: Array<HubDashboard | HubCommentDashboard>
): Array<Array<DashboardDropdownItem>> {
  const items: Array<Array<DashboardDropdownItem>> = []
  const groupedDashboards = groupBy(dashboards, 'rootOrganisationNodeId')

  for (const [organisationId, organisationDashboards] of Object.entries(groupedDashboards)) {
    const organisationItems: Array<DashboardDropdownItem> = [
      {
        id: organisationId,
        label: organisationDashboards[0].rootOrganisationNodeName,
        disabled: true
      }
    ]

    const children = organisationDashboards.map(d => {
      const routePathPrefix = d.type === 'comments' ? '/dashboard/comment' : '/dashboard'

      const isRouteMatched = route.path === `${routePathPrefix}/${d.id}`
      const { hash, ...relevantParams } = removeIrrelevantParams(params.value, d.type)

      let permissionRequired: string = 'Dashboards.Default.Organisation.Read'
      const dashboardType: string = d.type ? capitalise(d.type) : 'Default'

      if (dashboardType === 'Default') {
        permissionRequired = d.isOwner ? 'Dashboards.Default.Self.Read' : 'Dashboards.Default.Organisation.Read'
      } else {
        permissionRequired = `Dashboards.${dashboardType}.Read`
      }

      return {
        label: d.translateName ? t('dashboards.name.' + d.name) : d.name,
        dashboard: d,
        hidden: !checkPermissions({ permissions: permissionRequired }),
        to: {
          path: `${routePathPrefix}/${d.id}`,
          query: { ...relevantParams }
        },
        class: isRouteMatched ? 'bg-blue-default text-white' : undefined
      }
    })

    organisationItems.push(...children)
    items.push(organisationItems)
  }

  return items
}

useHead({
  meta: [
    { name: 'robots', content: 'noindex, nofollow' }
  ]
})
</script>
