<template>
  <div
    class="z-[60] flex w-full items-center justify-between bg-gradient-to-r from-trublue from-50% to-service to-90%
      py-1 sm:fixed print:hidden"
    :class="topStyle"
  >
    <div class="flex w-full items-center">
      <button
        type="button"
        class="white ml-4 inline-flex items-center rounded-lg text-sm md:hidden"
        @click="$emit('update:display-mobile-menu', !displayMobileMenu)"
      >
        <span class="sr-only">Open main menu</span>
        <UIcon
          :name="displayMobileMenu ? 'i-mdi-menu-open' : 'i-mdi-menu'"
          class="!size-6"
          style="color: white"
        />
      </button>

      <NuxtLink
        :to="userHomePage"
        class="w-full justify-center md:w-auto md:justify-normal"
      >
        <img
          src="~/assets/img/logo/truhub_logo_white.svg"
          class="mx-auto my-1 h-8 select-none md:ml-4"
          alt="TruRating Logo"
        >
      </NuxtLink>
      <AllTimeRatings class="hidden md:block" />
    </div>

    <div class="flex items-center">
      <div class="hidden min-w-36 justify-end md:flex print:hidden">
        <component
          :is="availableDashboardCount > 1 ? HubDropdown : NuxtLink"
          v-if="availableDashboardCount"
          :items="dashboardDropdownItems"
          :to="availableDashboardCount > 0 ? dashboardDropdownItems[0][1].to : undefined"
        >
          <HubButton
            class="text-white"
            :icon="availableDashboardCount > 1 ? 'i-mdi-chevron-down' : undefined"
            icon-location="right"
            icon-classes="ml-1"
            btn-style="bg-transparent mx-2 font-semibold min-w-full"
            :label="$t('dashboard', availableDashboardCount)"
          />
        </component>
      </div>
      <SidebarUserProfile class="mr-1 md:mx-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { HubDropdown, NuxtLink } from '#components'
import type { DashboardDropdownItem } from '~/types/component'

// props & emits
const props = defineProps<{
  displayMobileMenu: boolean
  dashboardDropdownItems: Array<Array<DashboardDropdownItem>>
  availableDashboardCount: number
  bannerShownAbove: boolean
}>()
defineEmits<{ (e: 'update:display-mobile-menu', arg1: boolean): void }>()

const { getCurrentUserDefaultDashboardId, fetchCurrentUserPreferences } = useUserStore()
const { fetchApiHealthCheck } = useHealthCheckStore()
await fetchCurrentUserPreferences()
await fetchApiHealthCheck()

const defaultDashboardId = computed(() => getCurrentUserDefaultDashboardId)
const topStyle = computed(() => props.bannerShownAbove ? 'sm:top-[37px]' : 'sm:top-0')
const userHomePage: ComputedRef<string> = computed(() =>
  defaultDashboardId.value ? '/dashboard/' + defaultDashboardId.value : '/'
)
</script>
