import cloneDeep from 'lodash/cloneDeep'
import { commentDashboardParamsArray, normalDashboardParamsArray, type DashboardParams } from '~/types/query-params'

export function removeIrrelevantParams<T extends DashboardParams>(
  params: T,
  dashboardType: 'default' | 'comments' | null
): Partial<T> {
  const relevantParams = cloneDeep(params)

  if (dashboardType === 'comments') {
    return relevantParams
  }

  // if dashboardType is default, remove all commentDashboardParams
  for (const key in relevantParams as DashboardParams) {
    if (commentDashboardParamsArray.includes(key as keyof DashboardParams)
      && !normalDashboardParamsArray.includes(key as keyof DashboardParams)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete relevantParams[key as keyof DashboardParams]
    }
  }

  return relevantParams
}
